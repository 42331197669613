body, html {
	font-family: "Rubik", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Rubik", sans-serif;
}

p, a, li, span, div {
	font-family: verdana, arial, helvetica, sans-serif;
}

.floating-ivw-logo {
	display: none;
}

// Social media links

.nav-social-media-links-img {
    display: none;
    padding-left: rem(14px);

	@include media-breakpoint-up(xl) {
		display: flex !important;
	}

    a {
        margin-left: 1.125rem;
		align-items: center;
        position: relative;
        overflow: hidden;
		display: flex;
        height: 48px;
        width: 48px;

        img {
            height: auto;
            width: 100%;
            left: 0;
            top: 0;
        }
    }
}

// smaller navi font
ul.sidenav__navigation li > a {
	font-size: 1.5rem;
}
ul.sidenav__navigation ul li > a {
    font-size: 1.2rem;
}
